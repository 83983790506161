import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SYSTEM_STRINGS from '../../utility/data/system/systemStrings.yaml';
import { useLocation } from '@reach/router';

/*---CVT---*/
import { CvtColorSchema, CvtBasicTheme } from '@conventic-web/internal.global';
import { HtmlContent } from '@conventic-web/internal.basic.html-content';
import { BlockTop } from '@conventic-web/internal.cvt.layout.block-top';
import { BlockStandard } from '@conventic-web/internal.cvt.layout.block-standard';

/*---Components---*/
import Layout from '../../components/skeleton_page/layout';
import Seo from '../../components/skeleton_page/seo';
import CardKontakteInput from '../../components/Kontakt_comps/cardKontakteInput';
// import { NewsletterFooter } from '../../components/Newsletter_comps/NewsletterFooter';

/*---Objects---*/
import { PaddingObj, DiagonalObj } from '../../global/sharedObjects';

import MarkdownIt from 'markdown-it';
const md = MarkdownIt({ html: true });

export const NewsletterPageTemplate = ({
  title,
  subtitle,
  image,
}: {
  title: string;
  subtitle: string;
  image: any;
}) => {
  return (
    <section className="section section--gradient">
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h1
          style={{
            color: 'white',
          }}
        >
          {title}
        </h1>
        <h3
          style={{
            color: 'white',
          }}
        >
          {subtitle}
        </h3>
      </div>
      <hr />

      <hr />
      <footer
        style={{
          backgroundColor: '#eeeeee',
        }}
      >
        Footer
      </footer>
    </section>
  );
};

NewsletterPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
};

const NewsletterPage = ({ data }: { data: any }) => {
  const { frontmatter } = data.markdownRemark;
  const location = useLocation();
  const featuredImage = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.resize
    : null;

  return (
    <Layout>
      <Seo
        title={frontmatter.pageTitle}
        keywords={frontmatter.seoKeywords}
        description={frontmatter.pageDescription}
        image={featuredImage}
        pathname={location.pathname}
      />
      <BlockTop
        headline={frontmatter.title}
        subheadline={frontmatter.subtitle}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={8}
        padding={PaddingObj.PaddingStarter}
        color={CvtColorSchema.white}
        bgImage={
          !frontmatter.image.childImageSharp &&
          frontmatter.image.extension === 'svg'
            ? frontmatter.image.publicURL
            : null
        }
      />
      <BlockStandard
        headline={frontmatter.newsletterSignOn.headline}
        subheadline={frontmatter.newsletterSignOn.subheadline}
        bgColor={CvtColorSchema.white}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={7}
        padding={PaddingObj.PaddingMore}
        color={CvtColorSchema.fontDarkGray}
      >
        {/* <NewsletterFooter /> */}
      </BlockStandard>
    </Layout>
  );
};

NewsletterPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default NewsletterPage;

export const newsletterPageQuery = graphql`
  query NewsletterPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        seoKeywords
        pageDescription
        featuredImage {
          childImageSharp {
            resize(width: 900) {
              src
              height
              width
            }
          }
        }
        title
        subtitle
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          extension
          publicURL
        }
        newsletterSignOn {
          headline
          subheadline
        }
      }
    }
  }
`;
